import * as React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import LayersIcon from '@material-ui/icons/Layers';
import { Link } from 'react-router-dom';

function ListItemLink(props) {
  const { icon, primary, to, selectedRouteName} = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  const selected = (to === selectedRouteName);

  return (
    <li>
      <ListItem button component={CustomLink} selected={selected}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  selectedRouteName: PropTypes.string
};

const activeRoute = (routeName) => {
  return props.location.pathname === routeName ? true : false;
}

export const mainListItems = (routeName) => (
  <div>
    <ListItemLink to="/listings" primary="Listings" icon={<DashboardIcon/>} selectedRouteName={routeName} />
    <ListItemLink to="/map" primary="Map" icon={<LayersIcon/>} selectedRouteName={routeName}/>
  </div>
);
