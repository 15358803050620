import React, { Component } from 'react'
import { auth } from '../utils/init'
import { Button, ButtonGroup } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'

class Menu extends Component {
  constructor(props){
    super(props)
    this.state = {
      path: this.path()
    }
    this.goBack = this.goBack.bind(this);
  }

  renderMenu = () => {
    console.log(this.path())
    if (this.props.isLoggedIn) {
      return(
       <>
        { this.renderLogOut() }
        { this.renderBack() }
        { (this.path() === '/listings') ? null : <Link to='/listings'>{this.renderButton("Listings")}</Link> }
        { (this.path() === '/map') ? null : <Link to='/map'>{this.renderButton("Map")}</Link> }
       </>
      )
    } else {
      return this.renderSignInUp()
    }
  }

  path = () => {
    return this.props.history.location.pathname
  }

  renderSignInUp = () => {
    return <Button color="primary" onClick={ this.props.auth.login }>Sign In / Sign Up</Button>
  }

  renderButton = (name) => {
    return <Button color="primary"> { name } </Button>
  }

  renderLogOut = () => {
    return <Button color="primary" onClick={ this.props.auth.logout}>Sign out</Button>
  }

  renderBack = () => {
    return <Button color="primary" onClick={ this.goBack }>Back</Button>
  }

  goBack(){
    this.props.history.goBack()
  }

  render() {
    return (
      <nav className="navbar navbar-dark bg-primary">
        { this.renderMenu() }
      </nav>
    )
  }
}

export default withRouter(Menu)
