import { api } from './init'
export function pingApiServer(){
    return api.get('/')
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error.response.data
        })
}

export function fetchListingsWithAuth(token){
    return api.get('/api/v1/listings', { headers: { 'Authorization': 'Bearer ' + token } })
        .then(function (response) {
            return response
        })
}

export function fetchListingInfoWithAuth(id, token){
    return api.get('/api/v1/listings/'+id, { headers: { 'Authorization': 'Bearer ' + token } })
        .then(function (response) {
            return response
        })
}
