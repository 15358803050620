import Listings from "../components/Listings";
import MapContainer from "../components/MapContainer";
import React, { Component } from 'react'
import { fetchListingsWithAuth } from './../utils/API'
import { Container,
         Row,
         Col,
         Button, 
         ButtonGroup } from 'reactstrap'
import real_estate_monopoly from '../images/real_estate_monopoly.jpg'

class Home extends Component {
  render(){
    return(
      <img src={real_estate_monopoly}  width={800}/>
    )
  }
}

export default Home
