import React from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { fetchListingsWithAuth } from './../utils/API'
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import fixedHeightPaper from '../dashboard/Dashboard'

const mapStyles = {
  width: '85%',
  height: '80%',
};

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listings: [],
      activeMarker: {},
      showingInfoWindow: false,
      selectedPlace: {},
    };
  }

  _logout = () => {
    localStorage.removeItem('id_token');
  }

  _fetchListingsWithAuth = () => {
      fetchListingsWithAuth(this.props.token)
      .then(response => {
        console.log("STATUS", response.status)
        if (response.status == 200) {
          return { success: true, data: response.data };
        } else {
          console.log("BEFORE throwing the ERROR");
          return { success: false, data: response.status };
        }
      })
      .catch(function(error) {
        console.log("ERROR", error);
        console.log("ERROR STATUS", error.response.status);
        return { success: false, data: error.response.status };
      })
      .then(response => { 
        this.setState({ loading: false })
        console.log("Response", response);
        if (response.success == true) {
          this.setState({ listings: response.data }) 
          console.log("state = ", this.state)
        } else if (response.data == 401) {
          this.setState({ isLoggedIn: false, token: null, currentUser: null }) 
          this._logout();
          console.log("state = ", this.state)
        } else {
          throw new Error("Network response was not ok.");
        }
      })
  }


  displayMarkers = () => {
    return this.state.listings.map((listing, index) => {
      return <Marker name={listing.building} key={index} id={index} position={{
       lat: listing.latitude,
       lng: listing.longitude
     }}
     title={'Unit# ' + listing.unit_number + ', ROI: ' + listing.roi_median }

     onClick={this.onMarkerClick} />
    })
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
  });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  displayInfoWindow = () => {
    if (!!this.state.activeMarker) {
      return <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
              <div className='info'>
                <h3>{this.state.selectedPlace.name}</h3>
                <h3>{this.state.activeMarker.title}</h3>
              </div>
          </InfoWindow>
    }
  }

  componentDidMount() {
    this._fetchListingsWithAuth();
  }

  render() {
    return (
      <div>
        <Grid item xs={12}> 
          <Paper className={fixedHeightPaper}>
            { this.state.loading && <LinearProgress color="secondary" /> }
            <Map
              google={this.props.google}
              onClick={this.onMapClicked}
              zoom={10}
              style={mapStyles}
              initialCenter={{ lat: 41.8988, lng: -87.6229}}
            >
              { this.displayMarkers()}
              { this.displayInfoWindow() }
            </Map>
          </Paper>
        </Grid> 
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCYuFwi70FwmWJSi9fo8lRD9Mm4ClIXqkc'
})(MapContainer);
