import React from "react";
import DataTable from "react-data-table-component";
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
// import {equal} from 'fast-deep-equal';
var equal = require('fast-deep-equal');

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear, fieldName }) => (
  <>
    <TextField id="search" type="text" placeholder={fieldName} aria-label="Search Input" value={filterText} onChange={onFilter} />
    <ClearButton type="button" onClick={onClear}>X</ClearButton>
  </>
);

class FilterDataTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { filterText: "", resetPaginationToggle: false, filteredItems: this.props.data};
  }

  handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: "",
        filteredItems: this.props.data
      });
    }
  };

  filterData(filterText) {
    let filteredItems = this.props.data.filter( (item) => this.props.filterFunction(item, filterText))
    this.setState({
      filteredItems: filteredItems
    })
  }

  componentDidMount() {
    this.filterData(this.state.filterText)
  }

  componentDidUpdate(prevProps) {
    if(!equal(this.props.data, prevProps.data))
    {
      this.filterData(this.state.filterText);
    }
  }

  getSubHeaderComponent = () => {
    return (
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filterData(newFilterText);
          this.setState({ filterText: newFilterText });
        }}
        onClear={this.handleClear}
        filterText={this.state.filterText}
        fieldName={this.props.filterField}
      />
    );
  };

  render() {
    return (
      <DataTable
        title="Active Listing"
        columns={this.props.columns}
        data={this.state.filteredItems}
        defaultSortField={this.props.defaultSortField}
        defaultSortAsc={false}
        paginationResetDefaultPage={this.state.resetPaginationToggle}
        subHeader
        subHeaderComponent={this.getSubHeaderComponent()}
        persistTableHead
        striped
        pagination
        fixedHeader
      />
    );
  }
}

export default FilterDataTable
