import React, { Component } from 'react'

import { auth } from '../utils/init'
import Home from './Home'
import Menu from './Menu'

import Routes from "../routes/Index";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { 
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import Dashboard from "../dashboard/Dashboard"

const theme = createMuiTheme({
  palette: {
    mode: 'light',
  },
});


class App extends Component {
  constructor(props){
    super(props)

    this.state = {
      isLoggedIn: auth.loggedIn(),
      currentUser: null,
      token: auth.getToken(),
      auth: auth,
    }
  }

  render() {
    const routes = <Routes {...this.state}/> 
    return (
        <ThemeProvider theme={theme}>
          <Router>
            <Dashboard {...this.state} routes={routes} />
          </Router>
        </ThemeProvider>
    )
  }
}

export default App
