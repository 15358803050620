import React from "react";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import HouseIcon from '@material-ui/icons/House';
import DataTable from "react-data-table-component";
import { fetchListingInfoWithAuth } from './../utils/API'

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import fixedHeightPaper from '../dashboard/Dashboard'

const columns = [
  {
    name: 'MLS #',
    selector: 'mls_number',
    sortable: true,
    width: '100px',
  },
  {
    name: 'MLS St',
    selector: 'mls_status',
    sortable: true,
    width: '80px',
  },
  {
    name: 'LMT',
    selector: 'listing_market_time',
    sortable: true,
    width: '70px',
  },
  {
    name: 'Building',
    selector: 'property.full_address',
    sortable: true,
    width: '350px',
  },
  {
    name: 'Unit #',
    selector: 'property.unit_number',
    sortable: true,
    compact: true,
    width: '60px',
  },
  {
    name: 'Beds',
    selector: 'property.beds',
    sortable: true,
    compact: true,
    width: '40px',
  },
  {
    name: 'Baths',
    selector: 'property.baths',
    sortable: true,
    compact: true,
    width: '40px',
  },
  {
    name: 'Listing Price',
    selector: 'listing_price',
    sortable: true,
    compact: true,
    width: '80px',
  },
  {
    name: 'Closed Price',
    selector: 'closed_price',
    sortable: true,
    compact: true,
    width: '80px',
  },
]
class ListingInfo extends React.Component {

  constructor(props) {
    super(props);
    // this.classes = useStyles();
    this.state = {
      listing: null,
    };
  }

  _fetchListingInfoWithAuth = () => {
    fetchListingInfoWithAuth(this.props.id, this.props.token)
    .then(response => {
      if (response.status = 200) {
        return response.data;
      }
      throw new Error("Network response was not ok.");
    })
    .then(response => this.setState({ listing: response }))
    .catch(() => this.props.history.push("/listings"));
  }

  componentDidMount() {
    this._fetchListingInfoWithAuth();
  }

  render() {
    const { listing } = this.state;

    if ( listing ) {
      return (
        <div>
          <Grid item xs={12}> 
            <Paper className={fixedHeightPaper}>
              <h1>Listing { this.props.id } (MLS# {listing.mls_number})</h1>
              <GridList cellHeight={180}>
                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                  <ListSubheader component="div">{listing.property.full_address} #{listing.property.unit_number}</ListSubheader>
                </GridListTile>
                <GridListTile key="details">
                  <HouseIcon/>
                  <GridListTileBar
                    title={"Beds: " + listing.property.beds + " Baths: " + listing.property.baths}
                    subtitle={listing.mls_status}
                  />
                </GridListTile>
              </GridList>
            </Paper> 
          </Grid>
          <Grid item xs={12} md={12} lg={12}> 
            <Paper className={fixedHeightPaper}>
              <DataTable
                title="Similar Rents"
                columns={columns}
                data={listing.similar_rents}
                defaultSortField="closed_price"
                defaultSortAsc={true}
                striped
              />
            </Paper> 
          </Grid>

          <Grid item xs={12} md={12} lg={12}> 
            <Paper className={fixedHeightPaper}>
              <DataTable
                title="Similar Sales"
                columns={columns}
                data={listing.similar_sales}
                defaultSortField="closed_price"
                defaultSortAsc={true}
                striped
              />
            </Paper> 
          </Grid>
        </div>
      )
    } else {
      return(
        <Grid item xs={12}> 
          <LinearProgress color="secondary" />
        </Grid> 
      )
    }
  }
}

export default ListingInfo
