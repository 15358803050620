import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { fetchListingsWithAuth } from './../utils/API'
import FilterDataTable from './FilterDataTable'
import useStyles from "../dashboard/Dashboard"

import real_estate_monopoly from '../images/real_estate_monopoly.jpg'
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import Paper from '@material-ui/core/Paper';
import fixedHeightPaper from '../dashboard/Dashboard'

const columns = [
  {
    name: 'MLS #',
    selector: 'mls_number',
    sortable: true,
    width: '100px',
    cell: row => <Link to={{ pathname: `/listings/${row.id}` }}>{row.mls_number}</Link>
  },
  {
    name: 'MLS St',
    selector: 'mls_status',
    sortable: true,
    width: '80px',
  },
  {
    name: 'LMT',
    selector: 'listing_market_time',
    sortable: true,
    width: '70px',
  },
  {
    name: 'Building',
    selector: 'building',
    sortable: true,
    width: '350px',
  },
  {
    name: 'Unit #',
    selector: 'unit_number',
    sortable: true,
    compact: true,
    width: '60px',
  },
  {
    name: 'Beds',
    selector: 'beds',
    sortable: true,
    compact: true,
    width: '40px',
  },
  {
    name: 'Baths',
    selector: 'baths',
    sortable: true,
    compact: true,
    width: '40px',
  },
  {
    name: 'Sq Ft',
    selector: 'sqft',
    sortable: true,
    width: '70px',
  },
  {
    name: 'Similar Sale Price (min/max/median)',
    selector: 'sale_prices',
    sortable: true,
    width: '180px',
  },
  {
    name: 'Listing Price',
    selector: 'listing_price',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Price Offset',
    selector: 'sale_price_median_offset',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Rent (min/max/median)',
    selector: 'rent_prices',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Taxes',
    selector: 'taxes',
    sortable: true,
    width: '90px',
  },
  {
    name: 'HOA',
    selector: 'hoa',
    sortable: true,
    width: '70px',
  },
  {
    name: 'Cap (Worst)',
    selector: 'cap_rate_worst',
    sortable: true,
    width: '100px',
  },
  {
    name: 'ROI (Worst)',
    selector: 'roi_worst',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Cash Flow (Worst)',
    selector: 'cash_flow_worst',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Cap (Best)',
    selector: 'cap_rate_best',
    sortable: true,
    width: '100px',
  },
  {
    name: 'ROI (Best)',
    selector: 'roi_best',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Cash Flow (Best)',
    selector: 'cash_flow_best',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Cap (Median)',
    selector: 'cap_rate_median',
    sortable: true,
    width: '100px',
  },
  {
    name: 'ROI (Median)',
    selector: 'roi_median',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Cash Flow (Median)',
    selector: 'cash_flow_median',
    sortable: true,
    width: '100px',
  },
];

class Listings extends React.Component {
  constructor(props) {
    super(props);
    console.log("IN LISTING COMPONENT", props);
    this.state = {
      loading: true,
      listings: [],
      isLoggedIn: props.isLoggedIn,
      currentUser: props.currentUser,
      token: props.token,
      auth: props.auth,
    };
  }

  _logout = () => {
    localStorage.removeItem('id_token');
  }

  _fetchListingsWithAuth = () => {
      console.log("this.state = ", this.state);
      fetchListingsWithAuth(this.state.token)
      .then(response => {
        console.log("STATUS", response.status)
        if (response.status == 200) {
          return { success: true, data: response.data };
        } else {
          console.log("BEFORE throwing the ERROR");
          return { success: false, data: response.status };
        }
      })
      .catch(function(error) {
        console.log("ERROR", error);
        console.log("ERROR STATUS", error.response.status);
        return { success: false, data: error.response.status };
      })
      .then(response => { 
        console.log("Response", response);
        this.setState({ loading: false })
        if (response.success == true) {
          this.setState({ listings: response.data }) 
          console.log("state = ", this.state)
        } else if (response.data == 401) {
          this.setState({ isLoggedIn: false, token: null, currentUser: null }) 
          this._logout();
          console.log("state = ", this.state)
        } else {
          throw new Error("Network response was not ok.");
        }
      })
  }

  componentDidMount() {
    this._fetchListingsWithAuth();
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Grid item xs={12}> 
          <Paper className={fixedHeightPaper}>
            { this.state.loading && <LinearProgress color="secondary" /> }
            <FilterDataTable
              columns={columns}
              data={this.state.listings}
              filterField="MLS# or Address"
              defaultSortField="roi_median"
              filterFunction={
                (item, filterText) => item.building && item.building.toLowerCase().includes(filterText.toLowerCase()) || item.mls_number && item.mls_number.includes(filterText)
              }
            />
          </Paper>
        </Grid> 
      )
    } else {
      return (
        <Grid item xs={12}> 
          <img src={real_estate_monopoly}  width={800}/>
        </Grid> 
      )
    }
  }
}
export default Listings;
