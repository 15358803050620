import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../components/Home";
import Listings from "../components/Listings";
import ListingInfo from "../components/ListingInfo";
import MapContainer from "../components/MapContainer";

class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      props: props,
    }
  }
  render() {
    return(
      <Switch>
        <Route path="/listings"
          exact
          render={
            (props) => <Listings
              {...this.state.props}
            />
          }
        />

        <Route path="/map"
          exact
          render={
            (props) => <MapContainer
              {...this.state.props}
            />
          }
        />

        <Route path="/listings/:id"
          render = {
            (props) => <ListingInfo
              { ...this.state.props }
              { ...props.match.params}
            />
          }
        />

        <Route path="/" exact component={Home} />
      </Switch>
    )
  }
}

export default Routes
